import { render, staticRenderFns } from "./StaffTable.vue?vue&type=template&id=3b60737f&scoped=true"
import script from "./StaffTable.vue?vue&type=script&lang=js"
export * from "./StaffTable.vue?vue&type=script&lang=js"
import style0 from "./StaffTable.vue?vue&type=style&index=0&id=3b60737f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b60737f",
  null
  
)

export default component.exports